import { Observable, throwError } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import { catchError } from 'rxjs/operators';
import { captureRequestError } from '@seequent/web-logger';

export const getResponseBody = (body: any) => ({ response: { ...body } });

export function addQueryParam(url: URL, key: string, value?: string | number) {
    // helper to add query params, using URL ensures the query param values are Encoded
    if (value !== undefined) {
        url.searchParams.append(key, String(value));
    }
}

function ignoreErrorCode(error: AjaxError) {
    // https://stackoverflow.com/questions/872206/what-does-it-mean-when-an-http-request-returns-status-code-0
    // ignore 401 and 404 from being logged into sentry, not necessary and should be handled with UI
    const ignored = [0, 401, 404];
    return ignored.includes(error.status);
}

/**
 * A pipe-able operator to use for logging errors to sentry from our rxjs Ajax requests.
 * @param shouldLogError An optional function used for conditionally logging to sentry.
 */
export function logErrorToSentry(shouldLogError: (error: AjaxError) => boolean = () => true) {
    return ($input: Observable<any>): Observable<any> =>
        $input.pipe(
            catchError((error: AjaxError) => {
                if (shouldLogError(error) && !ignoreErrorCode(error)) {
                    captureRequestError({
                        error,
                        url: error.xhr.responseURL ?? '',
                        method: error.request.method ?? '',
                        statusCode: error.status.toString(),
                    });
                }
                return throwError(() => error);
            }),
        );
}

/**
 * Do a best-effort to transform a response to JSON
 *
 * @param r An `AjaxResponse`, or an `AjaxError`, or an `Observable` of one of those two. We declare the type as
 *          `any` because things get a little complex.
 * @returns If the response contains parsable JSON the response is returned with `responseType` set to `'json'` and
 *          `response` set to the object returned by `JSON.parse`. Otherwise the passed in object is returned
 *          unmodified.
 */
export function transformResponseToJson(r: any): any {
    const { response } = r; // cant set default to 'null' here because empty string can be a valid return result
    try {
        const json = JSON.parse(response || 'null');
        return { ...r, responseType: 'json', response: json };
    } catch {
        // Not all responses from central are JSON.
        // Return original response if a JSON parse/syntax error occurs.
        return r;
    }
}
